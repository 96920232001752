import React, { useState } from 'react'
import {
  FaHtml5,
  FaCss3,
  FaJs,
  FaReact,
  FaGithubSquare,
  FaSass,
  FaTerminal,
  FaWordpress,
  FaUnity,
  FaNodeJs,
  FaAdobe,
  FaPython,
  FaCogs,
} from 'react-icons/fa'

export default function HomeSkills() {
  const [clicked, setClicked] = useState(false)

  function checkSkillsOut() {
    setClicked(!clicked)
  }

  return (
    <section className="skills__wrapper">
      <h3>Skills and Tools</h3>

      <ul className="skills__list">
        {/* ROW 1 */}
        <li className="si">
          <FaHtml5 />
          <p>HTML</p>
        </li>
        <li className="si">
          <FaCss3 />
          <p>CSS</p>
        </li>
        <li className="si">
          <FaJs />
          <p>JavaScript</p>
        </li>
        <li className="si">
          <FaReact />
          <p>React</p>
        </li>
        <li className="si">
          <FaNodeJs />
          <p>Node.Js</p>
        </li>
        <li className="si">
          <i className="devicon-express-original" />
          <p>Express</p>
        </li>
        <li className="si">
          <i className="devicon-git-plain" />
          <p>Git</p>
        </li>
        <li className="si">
          <FaWordpress />
          <p>Wordpress</p>
        </li>
        {/* ROW 2 */}
        <li className="si">
          <FaGithubSquare />
          <p>Github</p>
        </li>
        <li className="si">
          <FaCogs />
          <p>RESTful APIs</p>
        </li>
        <li className="si">
          <FaSass />
          <p>SASS</p>
        </li>
        <li className="si">
          <img
            src="/icons/styledcomponents.png"
            alt="styled components"
            width="50"
            height="50"
          />
          <p>Styled Components</p>
        </li>
        <li className="si">
          <FaAdobe />
          <p>Adobe CS Suite</p>
        </li>
        <li className="si">
          <img
            src="/icons/jestlogo.png"
            alt="Jest"
            width="52.5"
            height="52.5"
          />
          <p>Jest</p>
        </li>
        <li className="si">
          <FaTerminal />
          <p>CMD</p>
        </li>
        <li className="si">
          <svg viewBox="0 0 128 128">
            <path fill="none" d="M0 0h128v128H0z" />
            <path
              d="M88.69 88.11c-9 18.4-24.76 30.78-45.61 34.85a39.73 39.73 0 0 1-9.77 1.14c-12 0-23-5-28.34-13.19C-2.2 100-4.64 76.87 19 59.76c.48 2.61 1.46 6.19 2.11 8.31A38.24 38.24 0 0 0 10 81.1c-4.4 8.64-3.91 17.27 1.3 25.25 3.6 5.38 9.3 8.65 16.63 9.65a44 44 0 0 0 26.55-5c12.71-6.68 21.18-14.66 26.72-25.57a9.32 9.32 0 0 1-2.61-6A9.12 9.12 0 0 1 87.37 70h.34a9.15 9.15 0 0 1 1 18.25zm28.67-20.2c12.21 13.84 12.54 30.13 7.82 39.58-4.4 8.63-16 17.27-31.6 17.27a50.48 50.48 0 0 1-21-5.05c2.29-1.63 5.54-4.24 7.33-5.87a41.54 41.54 0 0 0 16 3.42c10.1 0 17.75-4.72 22.31-13.35 2.93-5.7 3.1-12.38.33-19.22a43.61 43.61 0 0 0-17.27-20.85 62 62 0 0 0-34.74-10.59h-2.93a9.21 9.21 0 0 1-8 5.54h-.31a9.13 9.13 0 0 1-.3-18.25h.33a9 9 0 0 1 8 4.89h2.61c20.8 0 39.06 7.98 51.42 22.48zm-82.75 23a7.31 7.31 0 0 1 1.14-4.73c-9.12-15.8-14-35.83-6.51-56.68C34.61 13.83 48.13 3.24 62.79 3.24c15.64 0 31.93 13.69 33.88 40.07-2.44-.81-6-2-8.14-2.44-.53-8.63-7.82-30.13-25.09-29.81-6.19.17-15.31 3.1-20 9.12a43.69 43.69 0 0 0-9.64 25.25 59.61 59.61 0 0 0 8.47 36.16 2.75 2.75 0 0 1 1.14-.16h.32a9.12 9.12 0 0 1 .33 18.24h-.33a9.16 9.16 0 0 1-9.12-8.79z"
              fill="#764abc"
            />
          </svg>
          <p>Redux</p>
        </li>

        {/* <button
          type="button"
          onClick={checkSkillsOut}
          style={{ marginBottom: '2rem' }}
        >
          {clicked ? 'See less...' : 'See more...'}
        </button> */}

        <ul className={`skills__list extras ${clicked ? '' : 'collapsed'}`}>
          {/* transition: { duration: 1 }, */}
          {/* other */}
          <li className="si">
            <i className="devicon-visualstudio-plain devicon" />
            <p>VS Code</p>
          </li>
          <li className="si">
            <FaPython />
            <p>Python</p>
          </li>
          <li className="si">
            <i className="devicon-csharp-plain" />
            <p>C#</p>
          </li>
          <li className="si">
            <i className="devicon-heroku-original" />
            <p>Heroku</p>
          </li>
          <li className="si">
            <i className="devicon-postgresql-plain" />
            <p>PostgreSql</p>
          </li>
          <li className="si">
            <i className="devicon-mongodb-plain-wordmark" />
            <p>MongoDb</p>
          </li>
          <li className="si">
            <img
              src="/icons/graphql.png"
              alt="graphql"
              width="52.5"
              height="52.5"
            />
            <p>GraphQL</p>
          </li>
          <li className="si">
            <img src="/icons/netlify.png" alt="netlify" />
            <p>Netlify</p>
          </li>
          <li className="si">
            <img src="/icons/vercel.png" alt="Vercel" />
            <p>Vercel</p>
          </li>
          <li className="si">
            <i className="devicon-webpack-plain" />
            <p>Webpack</p>
          </li>
          <li className="si">
            <img src="/icons/reactTestingLib.png" alt="Testing Library" />
            <p>React Testing Library</p>
          </li>
          <li className="si">
            <img src="/icons/pug.png" alt="pug.js" />
            <p>Pug</p>
          </li>
          <li className="si">
            <FaUnity />
            <p>Unity</p>
          </li>
        </ul>

        <button
          type="button"
          onClick={checkSkillsOut}
          style={{ marginTop: '2rem' }}
        >
          {clicked ? 'See less...' : 'See more...'}
        </button>
      </ul>
    </section>
  )
}
