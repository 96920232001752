export const showcaseList = [
  {
    id: 1,
    title: "Lorain's Blog",
    image: '/projects/lorainBlog.webp',
    description:
      'Lorain Ambrocio is a higher education professional assisting students through their education journey and mentoring on various aspects of the college and career application process. This blog is her story in the making.',
    hostedLink: 'https://lorainambrocio.com/',
    gitLink: '',
    blogLink: '',
    remodel: false,
    tags: [
      'html',
      'css',
      'sass',
      'gatsbyjs',
      'styledcomponents',
      'graphql',
      'contentful',
    ],
  },
  {
    id: 11,
    title: 'My Degree Coaching',
    image: '/projects/myDegreeCoaching.jpg',
    description:
      'A beautiful and modern website made for those looking to excel in their collegiate adventures.',
    hostedLink: 'https://mydegreecoaching.com/',
    gitLink: '',
    blogLink: '',
    remodel: false,
    tags: ['html', 'css', 'wordpress'],
  },
  {
    id: 2,
    title: 'Nails By Mai',
    image: '/projects/nailsByMai.jpg',
    description:
      'Mai is a Certified Manicurist is the state of Texas. Originally from Vietnam, Mai brings 15 years of experience in cosmetology. She adds a unique fusion of Vietnamese and American culture to her craft.',
    hostedLink: 'https://nailsbymai.com',
    gitLink: '',
    blogLink: '',
    remodel: false,
    tags: ['html', 'css', 'wordpress'],
  },
  {
    id: 3,
    title: "Watters' Advance",
    image: '/projects/wattersAdvance.jpg',
    description:
      'Wayne Watters is a Credit Union Guru. Wayne helps Credits Unions, all across the US, focus more heavily on online services for their members.',
    hostedLink: 'https://wattersadvance.com/',
    gitLink: '',
    blogLink: '',
    remodel: false,
    tags: ['squarespace'],
  },
  {
    id: 4,
    title: 'Mikes Pizza - Waukegan',
    image: '/projects/mikesPizza.webp',
    description:
      'A custom adaptation to Wes Bos’ Master Gatsby Course. Mikes Pizza Waukegan is a popular Pizza Joint in Illinois. Check out all the unique pizzas, slice-masters and site functionality. *No affiliation with Mikes Pizza of any kind, expressed or implied.*',
    hostedLink: 'https://mikes-pizza.markambrocio.com/',
    gitLink: 'https://github.com/markanator/slick-slice',
    blogLink: '',
    remodel: false,
    tags: ['html', 'sass', 'gatsbyjs', 'sanity.io'],
  },
]

// {
//   id: 454,
//   title: "Food Truck Track'r",
//   image: '/projects/foodTrucks.webp',
//   description:
//     'FoodTruck TrackR was designed to make finding and eating at a food truck fast, easy and fun. Find nearby food trucks that are currently open, view menu photos, read customer reviews and much more!',
//   hostedLink: 'https://food-truck-tracker.markambrocio.com/',
//   gitLink: 'https://github.com/foodtrucktrackr-bw/foodtrucktrackr-frontend',
//   blogLink: '',
//   remodel: true,
//   tags: [
//     'html',
//     'css',
//     'react',
//     'redux',
//     'nodejs',
//     'express',
//     'heroku',
//     'postgresql',
//   ],
// },
// {
//   id: 34,
//   title: 'How To Guides',
//   image: '/projects/howTos.jpg',
//   description:
//     'How-To Guides is a community for people who like to make and share projects, from cooking to tech. Posts with the most likes / reviews will be at the top of the feed, simplifying life for everyone.',
//   hostedLink: 'https://fullstack-howto-guides.vercel.app/',
//   gitLink: '',
//   blogLink: '',
//   remodel: true,
//   tags: ['html', 'css', 'gatsbyjs', 'express', 'heroku', 'postgresql'],
// },
// {
//   id: 75,
//   title: "Dang That's Yummy",
//   image: '/projects/darnYummy.png',
//   description:
//     "A custom adaptation to Wes Bos’ Learn Node Course. Dang That's Yummy is a collection of previous restaurants I have visited and enjoyed enough that it warranted its own site! See if we have any in common! 😀 ",
//   hostedLink: 'https://dang-thats-yummy.herokuapp.com/',
//   gitLink: 'https://github.com/markanator/dang-thats-delicious',
//   blogLink: '',
//   remodel: true,
//   tags: ['sass', 'pug', 'nodejs', 'express', 'heroku', 'mongodb'],
// },
// {
//   id: 43,
//   title: 'Virtual Reality Funding Platform',
//   image: '/projects/vrFunds.png',
//   description:
//     'Help minorities build virtual reality experiences to tell their story, building empathy and understanding. We want to build a platform that provides tech entrepreneurs with limited resources access to business training, project capital raising and mentoring. ',
//   hostedLink: 'https://fullstack-vrfunds.vercel.app/',
//   gitLink: 'https://github.com/virtual-reality-funding-platform1/back-end',
//   blogLink: '',
//   remodel: true,
//   tags: ['html', 'css', 'tailwindcss', 'react', 'firebase'],
// },
